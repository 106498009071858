<template>
  <div>
    <van-nav-bar title="预约记录" left-text="返回" @click-left="back" fixed left-arrow />
    <!-- 主体 -->
    <main class="login_form" height="100%">
      <div style="padding:7px;color:#999;font-size:13px;background:#fff;">
        <van-icon name="clock-o" />
        &nbsp;{{item.arrangeDate}}&nbsp;{{item.halfTime=='am'?'上午':'下午'}}&nbsp;{{item.startTime}}-{{item.endTime}}
        <span
          style="float:right;color:#333;margin-right:7px;"
        >{{['普通','专家','膏方'][item.type]}}</span>
      </div>
      <van-cell class="doctor-info doctor-info-small">
        <template #title>
          <div class="flex-row">
            <div>
              <van-image :src="item.avatar" class="doctor-avatar-image" />
            </div>
            <div
              class="flex1 flex-column justify-around"
              style="padding: 2px 8px;margin-left: 4px;"
            >
              <p class="bold" style="font-size:1.4em;">{{item.name}}</p>
              <p style="font-size:1em;color: #999;">{{item.title}}</p>
            </div>
            <div
              :style="'font-size:16px;font-weight:bold;float:right;display:inline;margin-top:10px;color:'+['Orange','gray','red','green'][item.status]"
            >{{['待就诊','取消','未就诊','已就诊'][item.status]}}</div>
          </div>
        </template>
      </van-cell>
      <van-cell class="black" title="预约姓名" :value="item.username"></van-cell>
      <van-cell class="black" title="手机号" :value="item.mobile"></van-cell>
      <van-cell class="black" title="就诊费用">
        <span>￥</span>
        <span style="font-size:22px;">{{item.fee}}</span>
      </van-cell>
      <van-cell class="black" title="登记时间" style="margin-top:10px;" :value="item.indate"></van-cell>
      <van-cell v-if="item.status == 1" title="取消时间" :value="item.cancelTime"></van-cell>
      <van-cell v-if="item.status == 1" title="取消人" :value="item.cancelUser"></van-cell>
      <van-cell v-if="item.status == 3" title="实际就诊时间" :value="item.attendTime"></van-cell>
      <van-button v-if="item.status == 0" class="cancel" @click="cancel" plain type="danger">取消预约</van-button>
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["userId", "isLogin"]),
  },

  data() {
    return {
      item: null,
    };
  },

  methods: {
    // 返回
    back() {
      this.$back();
    },
    //取消预约
    cancel() {
      //弹出确认
      this.$dialog
        .confirm({
          title: "提示",
          closeOnPopstate: true,
          message: "是否确认取消预约?",
        })
        .then(() => {
          const toast = this.$toast.loading({
            forbidClick: true,
            duration: 0,
          });
          this.$axios
            .get("/reserve-bill/cancel", {
              params: { id: this.$route.query.id, userId: this.$user.id },
            })
            .then((res) => {
              toast.clear();
              if (res.code == "ok") {
                this.$toast("取消成功");
                this.item = res.data;
                sessionStorage.listChange = true;
              } else {
                this.$toast(res.msg);
              }
            })
            .catch((err) => {
              toast.clear();
              console.log("err", err);
            });
        })
        .catch(() => {});
    },
    getDetail() {
      this.$axios
        .get("/reserve-bill/getDetail", {
          params: { id: this.$route.query.id },
        })
        .then((res) => {
          this.item = res.data;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
  create() {},
  mounted() {
    this.getDetail();
  },
};
</script>

<style lang="less">
.black .van-cell__value {
  color: #000;
}
.login_form {
  margin-top: 46px;
}

/* 本页公共样式 */
.gray {
  color: #797d82;
}

.bott0 {
  bottom: 0;
}

.orange {
  color: #ff5f16;
}

.yellow {
  color: #ffb232;
}

.bd-gray {
  border-bottom: 1px solid #f5f5f5;
}

// 头部图片
.beijin {
  background: #fff;
  height: 200px;
  background-size: cover;
  margin-top: -44px;
}

// 用户名和头像
.users {
  top: 55px;
  left: 22px;
  position: absolute;

  // 头像
  .imege {
    height: 65px;
    border-radius: 50%;
  }

  .fff {
    font-size: 22px;
  }
}
// 头像
.imege {
  height: 65px;
  border-radius: 50%;
}

// 导航
.nav {
  height: 75px;

  .iconfont {
    font-weight: bold;
  }

  .cit {
    height: 26px;
  }
}

.cancel {
  float: right;
  width: 100px;
  height: 36px;
  margin-right: 10px;
  margin-top: 10px;
}

// 主体
main {
  .list {
    height: 50px;

    .image {
      height: 20px;
    }
  }

  .foun {
    font-size: 12px;
  }
}

/* 底部 */
.footer {
  height: 50px;
}
</style>